import React from "react";
import "./ContactPage.css";
import dS from "../designSystem/designSystem";
import NormalTextComponent from "../styles/NormalTextComponent";

const ContactPage: React.FC = () => {
  const merchantPhoneNumber = "+4915234679042"; // Nummer für Händler
  const customerPhoneNumber = "+4915234679042"; // Nummer für Kunden
  const merchantMessage =
    "Hallo, ich bin ein Händler und habe eine Frage zu Ihren Dienstleistungen.";
  const customerMessage =
    "Hallo, ich bin ein Kunde und habe eine Frage zu Ihren Angeboten.";

  const createWhatsAppLink = (phoneNumber: string, message: string) =>
    `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="contact-container">
      <h1>Kontakt & Support</h1>
      <p className="contact-description">
        Habt ihr Fragen zu der App oder benötigt Unterstützung? Kontaktiert uns
        einfach & unkompliziert via WhatsApp und wir melden uns so schnell wie
        möglich.
      </p>
      <div className="button-container">
        <a
          href={createWhatsAppLink(merchantPhoneNumber, merchantMessage)}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button merchant"
        >
          <button
            style={{
              backgroundColor: dS.colors.basarColorDarkBlue,
            }}
          >
            Für Händler: Jetzt kontaktieren
          </button>
        </a>
        <a
          href={createWhatsAppLink(customerPhoneNumber, customerMessage)}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button customer"
        >
          <button style={{ backgroundColor: dS.colors.basarColorRed }}>
            Für Nutzer: Jetzt kontaktieren
          </button>
        </a>
      </div>
    </div>
  );
};

export default ContactPage;
