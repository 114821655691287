import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage: React.FC = () => {
  const [policyContent, setPolicyContent] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language || "en"; // Default zu Englisch
    const policyPath = `/docs/privacy-policy.${currentLanguage}.md`;

    fetch(policyPath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Privacy policy not found for language: ${currentLanguage}`);
        }
        return response.text();
      })
      .then((text) => setPolicyContent(text))
      .catch((error) => {
        console.error("Error fetching privacy policy:", error);
        setPolicyContent(t("privacyPolicyNotFound")); // Fallback-Nachricht
      });
  }, [i18n.language]); // Neu laden, wenn sich die Sprache ändert

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1>{t("Datenschutz")}</h1>
      {policyContent ? (
        <ReactMarkdown>{policyContent}</ReactMarkdown>
      ) : (
        <p>{t("loadingPrivacyPolicy")}</p>
      )}
    </div>
  );
};

export default PrivacyPolicyPage;
