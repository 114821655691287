import React from "react";
import './BenefitSection.css';
import dS from "../../designSystem/designSystem";

interface Benefit {
  icon: string;
  title: string;
  description: string;
}

const benefits: Benefit[] = [
  {
    icon: "🎯",
    title: "Neue Kunden",
    description: "Erreiche eine neue, lokal ansässige Kundschaft direkt auf deinem Smartphone.",
  },
  {
    icon: "🚀",
    title: "Leicht & Schnell",
    description: "Erstelle und verwalte Angebote in nur wenigen Minuten – so einfach wie ein Social Media Profil.",
  },
  {
    icon: "❤️",
    title: "Kostenlos",
    description: "Nutze alle Funktionen der App völlig kostenlos, ohne versteckte Gebühren.",
  },
  {
    icon: "💸",
    title: "Mehr Umsatz",
    description: "Steigere deinen Umsatz durch gezielte Sonderangebote und Coupons für deine Kunden.",
  },
  {
    icon: "🌍",
    title: "Mehr Reichweite",
    description: "Erhöhe deine Sichtbarkeit und Reichweite in deiner lokalen Community und darüber hinaus.",
  },
  {
    icon: "⭐️",
    title: "Bessere Bewertungen",
    description: "Erhalte wertvolles Feedback und verbessere deine Reputation durch Kundenbewertungen.",
  },
];

const BenefitSection: React.FC = () => {
  return (
    <div className="benefit-section">
      {benefits.map((benefit, index) => (
        <div className="benefit-item" key={index}>
          <div className="benefit-text">
            <h2
            style={{color: dS.colors.basarColorDarkBlue}}
            >
              <span role="img" aria-label={benefit.title}>
                {benefit.icon}
              </span>{" "}
              {benefit.title}
            </h2>
            <p>{benefit.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BenefitSection;
