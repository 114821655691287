import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./PartnerDetail.css"; // Stile für PartnerDetail
import { ReactComponent as PHONESVG } from "../../assets/images/phonesvg.svg";
import { ReactComponent as BASARPOI } from "../../assets/images/iconBasarPOI.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/images/app-store.svg";
import { ReactComponent as GooglePlayIcon } from "../../assets/images/google-store.svg";
import whatsappIcon from "../../assets/images/whatsapp-icon.png";

interface LocationState {
  name: string;
  formatted_address: string;
  placeId: string;
  photoUrl: string;
  types: string;
}

const PartnerDetail: React.FC = () => {
  const location = useLocation();
  const { name, formatted_address, photoUrl, types } =
    location.state as LocationState;
  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log(data); // Hier könntest du die Daten verarbeiten (z.B. absenden)
  };

  const [selectedOption, setSelectedOption] = useState<number | null>(1);

  const handleOptionClick = (option: number) => {
    setSelectedOption(option);
  };

  const renderContactOption = () => {
    switch (selectedOption) {
      case 1:
        return (
          <div className="contact-app">
            <h1>App herunterladen & registrieren</h1>
            <div className="app-download-icons">
              <a
                href="https://apps.apple.com/de/app/basar-partner/id6737979485"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreIcon className="app-store-icon" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.basardigital.basardigitalnew&hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GooglePlayIcon className="google-play-icon" />
              </a>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="contact-whatsapp">
            <h1>WhatsApp Chat starten</h1>
            <a href="https://wa.me/491789005991">
              <img
                src={whatsappIcon}
                alt="WhatsApp Icon"
                className="whatsapp-icon"
              />
              <p>Hier kannst du uns direkt über Whatsapp erreichen</p>
            </a>
          </div>
        );
      case 3:
        return (
          <div className="contact-form">
            <h1>Kontaktiere uns via Formular</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>Name</label>
              <br />
              <input
                placeholder="z.B. Max Mustermann"
                type="text"
                {...register("name", { required: true })}
              />
              <br />
              <br />
              <label>Email</label>
              <br />
              <input
                placeholder="z.B. basar@email.de"
                type="email"
                {...register("email", { required: true })}
              />
              <br />
              <br />
              <label>Telefonnummer</label>
              <br />
              <input
                placeholder="z.B. +49 123 456 789"
                type="tel"
                {...register("phone", { required: true })}
              />
              <br />
              <br />
              <button type="submit">Absenden</button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="partner-detail-container">
      <div className="google-data-container">
        <h2>
          Das könnte dein Geschäft auf der{" "}
          <span style={{ color: "var(--basar-red)" }}>BASAR</span> App sein.
        </h2>
        <BASARPOI className="basar-poi" />
        <div className="google-data">
          <div className="phone-mockup-container">
            <PHONESVG className="phone-svg" />
            <div className="partner-container">
              <p className="partner-name">{name}</p>
              <p className="partner-type">{types}</p>
              <div className="reviews">
                <div>Reviews</div>
                <div className="stars">4.9 ★ ★ ★ ★ ★</div>
                <div className="review-count">
                  999 Reviews, 666 Empfehlungen
                </div>
                <div className="user-review">
                  <div className="profile">
                    <img
                      src={
                        "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                      }
                      alt="Profilbild"
                      className="profile-img"
                    />
                    <div className="profile-info">
                      <p className="profile-name">Donald</p>
                      <p className="review-date">
                        <span className="user-stars">★ ★ ★ ★ ★ </span>|{" "}
                        <span className="date">May, 2024</span>
                      </p>
                    </div>
                  </div>
                  <p className="review-text">
                    "I did not know this place. Was so great! Will defintley
                    come back.."
                  </p>
                </div>
              </div>
            </div>

            <div className="image-wrapper">
              <img src={photoUrl} alt={name} className="google-image" />
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form-container">
        <h2>
          <span style={{ color: "var(--basar-red)" }}>Jetzt</span> loslegen
        </h2>
        <h3>{name}</h3>
        <p>📍 {formatted_address}</p>
        <div className="contact-container">
          <div className="contact-button-group">
            <button
              className={`contact-option-button ${
                selectedOption === 1 ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(1)}
            >
              App
            </button>
            <button
              className={`contact-option-button ${
                selectedOption === 2 ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(2)}
            >
              WhatsApp
            </button>
            <button
              className={`contact-option-button ${
                selectedOption === 3 ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(3)}
            >
              Kontaktformular
            </button>
          </div>
          {renderContactOption()}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default PartnerDetail;
