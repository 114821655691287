import React from "react";
import "./HowItWorksSteps.css"; // Stildefinitionen für die 3-Schritte-Anleitung
import step1Image from "../assets/images/steps/step1.png";
import step2Image from "../assets/images/steps/step2.png";
import step3Image from "../assets/images/steps/step3.png";
import { ReactComponent as STEP1ICON } from "../assets/images/step1icon.svg";
import { ReactComponent as STEP2ICON } from "../assets/images/step2icon.svg";
import { ReactComponent as STEP3ICON } from "../assets/images/step3icon.svg";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import dS from "../designSystem/designSystem";

const HowItWorksSteps: React.FC = () => {
  return (
    <div className="how-it-works-container">
      <HeaderTextComponent
        level={1}
        title="How it works"
        seoDescription="Easy as 1-2-3"
        fontWeight="bold"
        style={{ textAlign: "center", marginBottom: 0 }}
      >
        Einfach & Schnell
      </HeaderTextComponent>

      <div className="steps-section">
        {/* Step 1 */}
        <div className="step-column">
          <div
            style={{
              height: dS.responsive.height(33),
            }}
          >
            <STEP1ICON className="step-icon" />
            <HeaderTextComponent level={3} title="Entdecken">
              Alles da. Alles nah.
            </HeaderTextComponent>
            <NormalTextComponent
              size="M"
              seoDescription="Entdecke die Welt des lokalen Handels, ob Restaurants, Supermärkte, Friseur, Kosmetikstudios, Werkstätte und vieles mehr!"
            >
              Entdecke die Welt des lokalen Handels, ob Restaurants,
              Supermärkte, Friseur, Kosmetikstudios, Werkstätte und vieles mehr!
            </NormalTextComponent>
          </div>
          <img src={step1Image} alt="Step 1" className="step-image" />
        </div>

        {/* Step 2 */}
        <div className="step-column">
          <div
            style={{
              height: dS.responsive.height(33),
            }}
          >
            <STEP2ICON className="step-icon" />
            <HeaderTextComponent level={3} title="Choose a restaurant">
              Coupon einlösen.
            </HeaderTextComponent>
            <NormalTextComponent
              size="M"
              seoDescription=" Lokales zu unterstützen & dabei zu sparen geht nicht? Doch!
            Unsere Partner im lokalen Handel laden dich ein über Direktrabatte & Geschenke für Produkte oder Dienstleistungen."
            >
              Lokales zu unterstützen & dabei zu sparen geht nicht? Doch! Unsere
              Partner im lokalen Handel laden dich ein über Direktrabatte &
              Geschenke für Produkte oder Dienstleistungen.
            </NormalTextComponent>
          </div>

          <img src={step2Image} alt="Step 2" className="step-image" />
        </div>

        {/* Step 3 */}
        <div className="step-column">
          <div
            style={{
              height: dS.responsive.height(33),
            }}
          >
            <STEP3ICON className="step-icon" />
            <HeaderTextComponent level={3} title="Choose a restaurant">
              Sofort sparen. Dank Basar.
            </HeaderTextComponent>
            <NormalTextComponent
              size="M"
              seoDescription="Schnell und unkompliziert für alle. Alle Deals griffbereit in deiner Hosentasche.
Deine Buchung zeigst du ganz einfach dem Mitarbeiter im Geschäft."
            >
              Schnell und unkompliziert für alle. Alle Deals griffbereit in
              deiner Hosentasche. Deine Buchung zeigst du ganz einfach dem
              Mitarbeiter im Geschäft.
            </NormalTextComponent>
          </div>
          <img src={step3Image} alt="Step 3" className="step-image" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSteps;
