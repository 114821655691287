import React from "react";
import "./HowItWorksPartner.css"; // Style definitions for the HowItWorksSteps component
import step1Image from "../../assets/images/steps/step1.png";
import step2Image from "../../assets/images/steps/step2.png";
import step3Image from "../../assets/images/steps/step3.png";
import { ReactComponent as STEP1ICON } from "../../assets/images/step1icon.svg";
import { ReactComponent as STEP2ICON } from "../../assets/images/step2icon.svg";
import { ReactComponent as STEP3ICON } from "../../assets/images/step3icon.svg";
import HeaderTextComponent from "../../styles/HeaderTextComponent";
import NormalTextComponent from "../../styles/NormalTextComponent";
import dS from "../../designSystem/designSystem";

const HowItsWorksPartner: React.FC = () => {
  return (
    <div className="how-it-works-container">
      <HeaderTextComponent
        level={1}
        title="How it works for partners"
        seoDescription="Easy setup and management in three steps"
        fontWeight="bold"
        style={{ textAlign: "center", marginBottom: 0, color: dS.colors.basarColorDarkBlue }}
      >
        So einfach geht’s für Partner
      </HeaderTextComponent>

      <div className="steps-section">
        {/* Step 1: Register Store */}
        <div className="step-column">
          <STEP1ICON className="step-icon"
          />
          <HeaderTextComponent level={3} title="Register Your Store" />
          <NormalTextComponent
            size="M"
            seoDescription="Register your store quickly and easily to start reaching more customers."
          >
            Melde dein Geschäft schnell und unkompliziert an, um mehr Kunden zu erreichen.
          </NormalTextComponent>
          <img src={step1Image} alt="Step 1" className="step-image" />
        </div>

        {/* Step 2: Create Coupons */}
        <div className="step-column">
          <STEP2ICON className="step-icon" />
          <HeaderTextComponent level={3} title="Create Coupons" />
          <NormalTextComponent
            size="M"
            seoDescription="Create unique coupons for your customers, increase sales, and attract new clients with ease."
          >
            Erstelle individuelle Gutscheine für deine Kunden, steigere den Umsatz und gewinne neue Kunden – alles nach deinen Wünschen.
          </NormalTextComponent>
          <img src={step2Image} alt="Step 2" className="step-image" />
        </div>

        {/* Step 3: Manage Your Store */}
        <div className="step-column">
          <STEP3ICON className="step-icon" />
          <HeaderTextComponent level={3} title="Manage Your Store" />
          <NormalTextComponent
            size="M"
            seoDescription="Update your store details, including opening hours, images, and available coupons, anytime."
          >
            Verwalte dein Geschäft ganz einfach – passe Öffnungszeiten, Bilder und Gutscheine jederzeit nach Bedarf an.
          </NormalTextComponent>
          <img src={step3Image} alt="Step 3" className="step-image" />
        </div>
      </div>
    </div>
  );
};

export default HowItsWorksPartner;
